:root {
  --blue-100: #050a25;
  --blue-80: #0f1532;

  --grey-60: #626679;
  --grey-50: #999cad;
  --grey-40: #cfd2e0;
  --grey-30: #e6e8f2;
  --grey-20: #eef1f8;
  --grey-10: #f9fafe;

  --white: #ffffff;

  --red-100: #3b2a2a;
  --red-50: #b91c1c;
  --red-30: #f74949;
  --red-10: #fee2e2;

  --green-100: #25392d;
  --green-50: #16a34a;
  --green-10: #dcfce7;

  --yellow-100: #3b332a;
  --yellow-50: #d5b81f;
  --yellow-10: #fefbe2;

  --azure-100: #2a323b;
  --azure-60: #306df6;
  --azure-50: #1f73d5;
  --azure-10: #e2effe;

  --bs-progress-bar-bg: #050a25;
  --bs-secondary-bg: #cfd2e0;
  --bs-primary-rgb: 5, 10, 37;
}
