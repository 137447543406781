@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/public/fonts/Plus_Jakarta_Sans/PlusJakartaSans-VariableFont_wght.ttf')
    format('truetype');
  /* You can add more src definitions here for different file formats */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('/public/fonts/Roboto_Mono/RobotoMono-VariableFont_wght.ttf') format('truetype');
  /* You can add more src definitions here for different file formats */
  font-weight: normal;
  font-style: normal;
}

body {
  color: var(--green-100);
  font-feature-settings:
    'clig' off,
    'liga' off;
  /* Paragraph DSK/p M */
  font-family: 'Plus Jakarta Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.16px;
}
