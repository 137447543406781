@font-face {
  font-family: 'GilroyBold';
  src:
    local('GilroyBold'),
    url('../fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'GilroyHeavy';
  src:
    local('GilroyHeavy'),
    url('../fonts/Gilroy/Gilroy-Heavy.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'GilroyLight';
  src:
    local('GilroyLight'),
    url('../fonts/Gilroy/Gilroy-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'GilroyMedium';
  src:
    local('GilroyMedium'),
    url('../fonts/Gilroy/Gilroy-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'GilroyRegular';
  src:
    local('GilroyRegular'),
    url('../fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'GilroyRegular', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3f4147 !important;
  font-size: 12px;
  letter-spacing: 0;
}

/* These styles are used if a demo specific stylesheet is not present */

.AppWrapper *,
.AppWrapper *:before,
.AppWrapper *:after {
  box-sizing: border-box;
}

.AppWrapper body,
.AppWrapper html {
  background-color: #f6f9fc;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #29c5f1 !important;
  border-color: #29c5f1 !important;
}

@keyframes pulse {
  0% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 12px rgba(90, 153, 212, 0);
  }

  100% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}

@media (max-width: 992px) {
  .widget-align-right#hubspot-messages-iframe-container {
    bottom: 78px !important;
  }
}
